.login-page {
    background-image: url('./images/login-image.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
}

.logo {
    height: 4rem;
}

.card {
    padding: 3rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
    background-color: #fff;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.card input[type="text"],
.card input[type="password"] {
    width: 100%;
    padding: 1rem;
    border-radius: 0.25rem;
    border: none;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.card button[type="submit"] {
    background-color: #0077b6;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    transition: background-color 0.3s ease-in-out;
}

.card button[type="submit"]:hover {
    background-color: #023e8a;
}

@media screen and (max-width: 480px) {
    .card {
        max-width: 90%;
    }
}