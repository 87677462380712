
table {
    border-collapse: collapse;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #4c95af;
    color: white;
  }

  body {
    overflow-x: hidden;
    position: relative;
    margin-top: 70px;
  }
  
  .participantes-page::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../Home/images/GAMITOPadel.png');
    background-size: 60%; /* Ajusta el tamaño de la imagen (porcentaje o valor en píxeles) */
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.1; /* Ajusta la opacidad de la imagen (valor entre 0 y 1, siendo 1 totalmente visible) */
    z-index: -1; /* Colocamos el seudoelemento detrás del contenido del body */
  }
  
  .navbar {
    position: fixed; /* Fija el Navbar en la parte superior */
    top: 0;
    left: 0;
    right: 0;
    z-index: 100; /* Asegura que el Navbar esté en la capa superior */
  }

